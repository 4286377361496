// extracted by mini-css-extract-plugin
export var btn = "House-module--btn--0c1fd";
export var btns = "House-module--btns--0178f";
export var container = "House-module--container--f3134";
export var enterprise = "House-module--enterprise--18695";
export var image = "House-module--image--38d0e";
export var location = "House-module--location--d1cd6";
export var mission = "House-module--mission--66f20";
export var pretitle = "House-module--pretitle--cafcb";
export var projects = "House-module--projects--4fa97";
export var text = "House-module--text--7946a";