import React, { useState } from 'react'
import { PageProps } from 'gatsby'
import { useApi } from '~/siteApi'
import {
  Head,
  Hero,
  Button,
  Lazyload,
  AttendanceForm,
  Form,
  Link,
  Footer,
  Lightbox,
} from '~/components'
import { scroller } from 'react-scroll'
import { useMediaQuery } from 'react-responsive'
import Slider, { ArrowType } from '@fil1pe/react-slider'
import { Image } from '~/components/Lightbox'
import cn from 'classnames'
import * as st from '~/assets/styl/House.module.styl'
import * as st2 from '~/assets/styl/NotFound.module.styl'
import Banner from '~/assets/img/Banner-House.jpg'

interface Enterprise {
  image: string
  title: string
  status: string
  district: string
  city: string
  state: {
    name: string
    acronym: string
  }
  galleryHouse: Array<{
    zoom: string
    legend: string
  }>
}

const House = ({ location, pageContext }: PageProps) => {
  const { houses: projects }: { houses: Array<Enterprise> } = useApi(
    pageContext,
    'houses'
  )

  const [images, setImages] = useState<Array<Image>>()
  const [index, setIndex] = useState(0)

  const mobile = !useMediaQuery({
    query: '(min-width: 701px)',
  })

  const { subjects }: { subjects: Array<{ name: string; id: string }> } =
    useApi({ subjects: [] }, 'subjects')

  return (
    <>
      <Head
        location={location}
        title={'Holder House - ' + process.env.GATSBY_SITE_NAME}
      />
      <Hero
        title="Holder House"
        description={
          'Residências de Alto Padrão.\nExcelente acabamento e execução.'
        }
        image={Banner}
      >
        <div className={st2.btns}>
          <Button
            className={st2.btn}
            onClick={() =>
              scroller.scrollTo('Atendimento', {
                smooth: true,
                duration: 500,
                offset:
                  -0.65 * document.getElementById('menu-bar').offsetHeight,
              })
            }
          >
            Saiba mais
          </Button>
        </div>
      </Hero>

      <section className={st.mission}>
        <div className={st.container}>
          <div>
            <h2>Construindo lares</h2>
            <div className={st.text}>
              <div>
                <p>
                  A nossa missão é fazer com que o sonho do cliente seja
                  concretizado, é muito mais que construir uma casa, é o
                  compromisso de construir um lar.
                </p>
              </div>
              <div>
                <p>
                  Prezamos pela qualidade, agilidade e comprometimento com o
                  cliente, construímos com amor e dedicação para entregar o
                  melhor com uma mão de obra especializada em casas de auto
                  padrão.
                </p>
                <div className={st.btns}>
                  <Button
                    onClick={() =>
                      scroller.scrollTo('Projetos', {
                        smooth: true,
                        duration: 500,
                        offset:
                          -0.65 *
                          document.getElementById('menu-bar').offsetHeight,
                      })
                    }
                  >
                    Conheça nossos projetos
                  </Button>
                  <Button
                    onClick={() =>
                      scroller.scrollTo('Atendimento', {
                        smooth: true,
                        duration: 500,
                        offset:
                          -0.65 *
                          document.getElementById('menu-bar').offsetHeight,
                      })
                    }
                  >
                    Solicite mais informações
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={st.projects} id="Projetos">
        <div className={st.container}>
          <h2>Projetos Holder House</h2>
          <p>Conheça nossos projetos executados e em construção.</p>
          <Slider
            slidesToShow={mobile ? 1 : 3}
            className="slider"
            renderArrow={({ className, ...props }, type) => (
              <button
                {...props}
                className={cn(
                  className,
                  type === ArrowType.Next ? 'next' : 'prev'
                )}
              ></button>
            )}
          >
            {projects.map(
              (
                {
                  image,
                  status,
                  title,
                  district,
                  city,
                  state: { acronym: state },
                },
                index
              ) => (
                <div
                  className={st.enterprise}
                  key={index}
                  onClick={() => {
                    setIndex(0)
                    setImages(
                      projects[index].galleryHouse.map(
                        ({ zoom: url, legend }) => ({
                          url,
                          legend,
                        })
                      )
                    )
                  }}
                >
                  <Lazyload src={image} className={st.image} />
                  {status && <span className={st.pretitle}>{status}</span>}
                  <h3>{title}</h3>
                  <span className={st.location}>
                    {district ? district + ' - ' : ''}
                    {city} - {state}
                  </span>
                </div>
              )
            )}
          </Slider>
          <div className={st.btn}>
            <Button
              onClick={() =>
                scroller.scrollTo('Atendimento', {
                  smooth: true,
                  duration: 500,
                  offset:
                    -0.65 * document.getElementById('menu-bar').offsetHeight,
                })
              }
            >
              Solicite mais informações
            </Button>
          </div>
        </div>
      </section>

      <AttendanceForm title="Atendimento" id="Atendimento">
        <Form
          path="customers-attendancies"
          idPrefix="contact"
          button={<Button>Enviar mensagem</Button>}
          loadingButton={<Button>Enviando...</Button>}
          inputs={[
            {
              name: 'name',
              label: 'Nome*',
            },
            {
              name: 'email',
              label: 'E-mail*',
              type: 'email',
            },
            {
              name: 'whatsapp',
              label: 'WhatsApp*',
              mask: 'phone',
            },
            {
              name: 'subject',
              label: 'Assunto*',
              type: 'select',
              options: subjects.map(({ name, id: value }) => ({
                name,
                value,
              })),
              placeholder: 'Selecione',
            },
            {
              name: 'message',
              label: 'Mensagem*',
              type: 'textarea',
              className: 'wide',
            },
            {
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link href="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: 'wide',
            },
          ]}
        />
      </AttendanceForm>

      <Footer />

      <Lightbox
        images={images}
        index={index}
        setImages={setImages}
        setIndex={setIndex}
      />
    </>
  )
}

export default House
